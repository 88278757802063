<template>
  <div>
    <DataGridFactory
      :dataSource="store.DocCstIpi.table"
      :enabledActions="false"
      :nameToExport="'Totais IPI por CST'"
    >
      <template v-slot:column>
        <DxColumn data-field="cstipi" alignment="right" caption="CST" />
        <DxColumn
          data-field="vprod"
          data-type="number"
          caption="Produtos"
          :format="{ type: 'fixedPoint', precision: 2 }"
        />
        <DxColumn
          data-field="vfrete"
          data-type="number"
          caption="+ Fretes"
          :format="{ type: 'fixedPoint', precision: 2 }"
        />
        <DxColumn
          data-field="vseg"
          data-type="number"
          caption="+ Seguros"
          :format="{ type: 'fixedPoint', precision: 2 }"
        />
        <DxColumn
          data-field="voutro"
          data-type="number"
          caption="+ Outros"
          :format="{ type: 'fixedPoint', precision: 2 }"
        />
        <DxColumn
          data-field="vipi"
          data-type="number"
          caption="= IPI"
          :format="{ type: 'fixedPoint', precision: 2 }"
        />
        <DxColumn
          data-field="vipidevol"
          data-type="number"
          caption="IPI Devol"
          :format="{ type: 'fixedPoint', precision: 2 }"
        />
      </template>
      <template v-slot:footer>
        <DxExport :enabled="true" />
        <DxSummary>
          <DxTotalItem
            column="cstipi"
            alignment="left"
            summary-type="count"
            display-format="{0} Registros"
         />
          <DxTotalItem
            column="vprod"
            alignment="right"
            summary-type="sum"
            display-format="R$: {0}"
            :value-format="{type: 'fixedPoint', precision: 2, currency: 'BRL'}"
          />
          <DxTotalItem
            column="vfrete"
            alignment="right"
            summary-type="sum"
            display-format="R$: {0}"
            :value-format="{type: 'fixedPoint', precision: 2, currency: 'BRL'}"
          />
          <DxTotalItem
            column="vseg"
            alignment="right"
            summary-type="sum"
            display-format="R$: {0}"
            :value-format="{type: 'fixedPoint', precision: 2, currency: 'BRL'}"
          />
          <DxTotalItem
            column="voutro"
            alignment="right"
            summary-type="sum"
            display-format="R$: {0}"
            :value-format="{type: 'fixedPoint', precision: 2, currency: 'BRL'}"
          />
          <DxTotalItem
            column="vipi"
            alignment="right"
            summary-type="sum"
            display-format="R$: {0}"
            :value-format="{type: 'fixedPoint', precision: 2, currency: 'BRL'}"
          />
          <DxTotalItem
            column="vipidevol"
            alignment="right"
            summary-type="sum"
            display-format="R$: {0}"
            :value-format="{type: 'fixedPoint', precision: 2, currency: 'BRL'}"
          />
        </DxSummary>
      </template>
    </DataGridFactory>
  </div>
</template>
<script lang="ts" src="./index.ts"></script>